/* eslint-disable no-return-assign */
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Select, TextInput } from 'ui-toolkit-tailwind/src/components'

dayjs.extend(quarterOfYear)

const useDebounce = (callback, delay) => {
	const timeoutRef = useRef(null)

	return useCallback(
		(...args) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}

			timeoutRef.current = setTimeout(() => {
				callback(...args)
			}, delay)
		},
		[callback, delay],
	)
}

const dateRangeEnum = Object.freeze({
	'Past 7 Days': 'past7Days',
	'Past 30 Days': 'past30Days',
	'Week-to-Date': 'weekToDate',
	'Month-to-Date': 'monthToDate',
	'Quarter-to-Date': 'quarterToDate',
	'Year-to-Date': 'yearToDate',
	'Last Month': 'lastMonth',
	'Last Quarter': 'lastQuarter',
	'Last Year': 'lastYear',
	Custom: 'custom',
})

/**
 * @typedef {{ from: string, to: string }} dateRange
 */

/**
 * @typedef DateFilterProps
 * @property {function(dateRange)} onChange
 */

/**
 * @param {DateFilterProps} props
 */
const DateFilter = ({ onChange = () => {} }) => {
	const rangeOptions = useMemo(() => {
		const ranges = { ...dateRangeEnum }

		const options = Object.entries(ranges).map(([label, value]) => ({
			value,
			label,
		}))

		return options
	}, [])

	const [value, setValue] = useState(rangeOptions[0].value)

	// custom date range
	const [fromDateStr, setFromDateStr] = useState('')
	const [toDateStr, setToDateStr] = useState('')
	const [isFromDateAfterToDate, setIsFromDateAfterToDate] = useState(false)
	const [customDateRange, setCustomDateRange] = useState({ from: '', to: '' })

	useEffect(() => {
		if (fromDateStr && toDateStr) {
			// check if form date is after to date
			if (dayjs(fromDateStr).isAfter(dayjs(toDateStr))) {
				setIsFromDateAfterToDate(true)
				return
			} else {
				setIsFromDateAfterToDate(false)
			}

			setCustomDateRange({ from: fromDateStr, to: toDateStr })
		}
	}, [fromDateStr, toDateStr])

	const handleDateRangeChange = useCallback(() => {
		const today = dayjs()
		const endDay = today.subtract(1, 'days') // default to yesterday
		let from
		let to = endDay

		const { from: customFrom, to: customTo } = customDateRange

		switch (value) {
			case dateRangeEnum['Past 7 Days']:
				from = today.subtract(7, 'days')
				break
			case dateRangeEnum['Past 30 Days']:
				from = today.subtract(30, 'days')
				break
			case dateRangeEnum['Week-to-Date']:
				from = today.startOf('week')
				break
			case dateRangeEnum['Month-to-Date']:
				from = today.startOf('month')
				break
			case dateRangeEnum['Quarter-to-Date']:
				from = today.startOf('quarter')
				break
			case dateRangeEnum['Year-to-Date']:
				from = today.startOf('year')
				break
			case dateRangeEnum['Last Month']:
				from = today.subtract(1, 'month').startOf('month')
				to = today.subtract(1, 'month').endOf('month')
				break
			case dateRangeEnum['Last Quarter']:
				from = today.subtract(1, 'quarter').startOf('quarter')
				to = today.subtract(1, 'quarter').endOf('quarter')
				break
			case dateRangeEnum['Last Year']:
				from = today.subtract(1, 'year').startOf('year')
				to = today.subtract(1, 'year').endOf('year')
				break
			case dateRangeEnum.Custom:
				from = dayjs(customFrom)
				to = dayjs(customTo)
				break
			default:
		}

		// if custom date range is not set, return
		if (value === dateRangeEnum.Custom && (!customFrom || !customTo)) return

		if (value !== dateRangeEnum.Custom) {
			setToDateStr('')
			setFromDateStr('')
		}

		onChange({ from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD') })
	}, [value, customDateRange, onChange])

	const handleDateRangeChangeDebounced = useDebounce(
		handleDateRangeChange,
		value === dateRangeEnum.Custom ? 1000 : 0,
	)

	useEffect(handleDateRangeChangeDebounced, [handleDateRangeChangeDebounced])

	return (
		<>
			<h3 className="mb-1 ml-1 text-xs font-semibold text-gray-600">Date Range</h3>
			<div className="flex flex-col justify-start w-full gap-3 sm:items-center sm:flex-row">
				<div className="flex items-center gap-3">
					<Select
						className="w-full sm:!w-[250px]"
						options={rangeOptions}
						value={value}
						onChange={setValue}
					/>
				</div>

				{value === dateRangeEnum.Custom && (
					<div className="flex gap-3">
						<TextInput
							className="flex-1 sm:!w-[150px]"
							placeholder="From"
							type="text"
							value={fromDateStr}
							onChange={e => setFromDateStr(e.target.value)}
							onFocus={e => (e.target.type = 'date')}
							onBlur={e => (e.target.type = e.target.value ? 'date' : 'text')}
						/>
						<TextInput
							className="flex-1 sm:!w-[150px]"
							placeholder="To"
							type="text"
							value={toDateStr}
							onChange={e => setToDateStr(e.target.value)}
							onFocus={e => (e.target.type = 'date')}
							onBlur={e => (e.target.type = e.target.value ? 'date' : 'text')}
							invalid={isFromDateAfterToDate}
						/>
					</div>
				)}
			</div>
		</>
	)
}

export default DateFilter
