/* eslint-disable jsx-a11y/heading-has-content */

'use client'

import * as Headless from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { cx } from 'class-variance-authority'
import clsx from 'clsx'
import { LayoutGroup, motion } from 'framer-motion'
import { forwardRef, Fragment, useEffect, useId, useState } from 'react'
import { TouchTarget } from './button'
import { Link } from './link'

export function Sidebar({ className, ...props }) {
	return <nav {...props} className={clsx(className, 'flex h-full min-h-0 flex-col')} />
}

export function SidebarHeader({ className, ...props }) {
	return (
		<div
			{...props}
			className={clsx(
				className,
				'flex flex-col border-b border-zinc-950/5 p-4 dark:border-white/5 [&>[data-slot=section]+[data-slot=section]]:mt-2.5',
			)}
		/>
	)
}

export function SidebarBody({ className, ...props }) {
	return (
		<div
			{...props}
			className={clsx(
				className,
				'flex flex-1 flex-col overflow-y-auto p-4 [&>[data-slot=section]+[data-slot=section]]:mt-8',
			)}
		/>
	)
}

export function SidebarFooter({ className, ...props }) {
	return (
		<div
			{...props}
			className={clsx(
				className,
				'flex flex-col border-t border-zinc-950/5 p-4 dark:border-white/5 [&>[data-slot=section]+[data-slot=section]]:mt-2.5',
			)}
		/>
	)
}

export function SidebarSection({ className, ...props }) {
	const id = useId()

	return (
		<LayoutGroup id={id}>
			<div {...props} data-slot="section" className={clsx(className, 'flex flex-col gap-0.5')} />
		</LayoutGroup>
	)
}

export function SidebarDivider({ className, ...props }) {
	return (
		<hr
			{...props}
			className={clsx(className, 'my-4 border-t border-zinc-950/5 lg:-mx-4 dark:border-white/5')}
		/>
	)
}

export function SidebarSpacer({ className, ...props }) {
	return <div aria-hidden="true" {...props} className={clsx(className, 'mt-8 flex-1')} />
}

export function SidebarHeading({ className, ...props }) {
	return (
		<h3
			{...props}
			className={clsx(
				className,
				'mb-1 px-2 text-xs/6 font-medium text-zinc-500 dark:text-zinc-400',
			)}
		/>
	)
}

export const SidebarItem = forwardRef(
	(
		{ current, className, routerLink, children, ...props },

		ref,
	) => {
		const classes = clsx(
			// Base
			'flex w-full items-center gap-3 rounded-lg px-2 py-2.5 text-left text-base/6 font-medium text-zinc-950 sm:py-2 sm:text-sm/5',
			// Leading icon/icon-only
			'data-[slot=icon]:*:size-6 data-[slot=icon]:*:shrink-0 data-[slot=icon]:*:fill-zinc-500 sm:data-[slot=icon]:*:size-5',
			// Trailing icon (down chevron or similar)
			'data-[slot=icon]:last:*:ml-auto data-[slot=icon]:last:*:size-5 sm:data-[slot=icon]:last:*:size-4',
			// Avatar
			'data-[slot=avatar]:*:-m-0.5 data-[slot=avatar]:*:size-7 data-[slot=avatar]:*:[--ring-opacity:10%] sm:data-[slot=avatar]:*:size-6',
			// Hover
			'data-[hover]:bg-zinc-950/5 data-[slot=icon]:*:data-[hover]:fill-zinc-950',
			// Active
			'data-[active]:bg-zinc-950/5 data-[slot=icon]:*:data-[active]:fill-zinc-950',
			// Current
			'data-[slot=icon]:*:data-[current]:fill-zinc-950',
			// Dark mode
			'dark:text-white dark:data-[slot=icon]:*:fill-zinc-400',
			'dark:data-[hover]:bg-white/5 dark:data-[slot=icon]:*:data-[hover]:fill-white',
			'dark:data-[active]:bg-white/5 dark:data-[slot=icon]:*:data-[active]:fill-white',
			'dark:data-[slot=icon]:*:data-[current]:fill-white',
		)

		return (
			<span className={clsx(className, 'relative')}>
				{current && (
					<motion.span
						layoutId="current-indicator"
						className="absolute inset-y-2 -left-4 w-0.5 rounded-full bg-zinc-950 dark:bg-white"
					/>
				)}
				{'href' in props || routerLink ? (
					<Headless.CloseButton as={Fragment} ref={ref}>
						<Link
							className={classes}
							routerLink={routerLink}
							{...props}
							data-current={current ? 'true' : undefined}
						>
							<TouchTarget>{children}</TouchTarget>
						</Link>
					</Headless.CloseButton>
				) : (
					<Headless.Button
						{...props}
						className={clsx('cursor-default', classes)}
						data-current={current ? 'true' : undefined}
						ref={ref}
					>
						<TouchTarget>{children}</TouchTarget>
					</Headless.Button>
				)}
			</span>
		)
	},
)

export function SidebarLabel({ className, ...props }) {
	return <span {...props} className={clsx(className, 'truncate')} />
}

export function SidebarExpandableItem({ expanded, name, items = [], onClick = () => {} }) {
	const [open, setOpen] = useState(expanded)

	useEffect(() => {
		setOpen(expanded)
	}, [expanded])

	return (
		<div className="w-full max-w-md">
			<SidebarItem
				className={cx(
					'flex items-center justify-between w-full cursor-pointer',
					open && 'bg-zinc-950/5 rounded-lg',
				)}
				onClick={() => {
					onClick()
					setOpen(!open)
				}}
			>
				<span className={cx(open ? 'text-zinc-950' : 'text-zinc-500')}>{name}</span>
				<ChevronDownIcon
					className={cx(
						'size-5 transition-transform duration-200 ease-out',
						open && 'rotate-180',
						open ? 'text-zinc-950' : 'text-zinc-500',
					)}
				/>
			</SidebarItem>

			<motion.div
				initial={false}
				animate={{
					height: open ? 'auto' : 0,
				}}
				transition={{
					duration: 0.2,
					ease: 'easeOut',
				}}
				className="overflow-hidden"
			>
				<div className="origin-top">
					<SidebarSection className="mt-0.5 py-2.5 px-2 rounded-lg bg-zinc-950/5">
						{items.map((item, index) => (
							<SidebarItem key={index} routerLink={item.path}>
								<span className={cx(item.current ? 'text-zinc-950' : 'text-zinc-500')}>
									{item.name}
								</span>
							</SidebarItem>
						))}
					</SidebarSection>
				</div>
			</motion.div>
		</div>
	)
}
