import dayjs from 'dayjs'
import VgtPerformanceMetric from 'shared/models/VgtPerformanceMetric'
import { displayAmount, displayDate } from 'shared/utilities/helpers'
import { Table } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef DailyBreakdownTableProps
 * @property {VgtPerformanceMetric[]} data
 */

/**
 * @param {DailyBreakdownTableProps} props
 */
const DailyBreakdownTable = ({ data }) => {
	return (
		<Table
			data={data?.sort((a, b) => a.dateLogged - b.dateLogged)}
			columns={[
				{
					name: 'dateLogged',
					label: 'Day of the Week',
					formatter: date => dayjs(date).format('dddd'),
				},
				{ name: 'dateLogged', label: 'Date', formatter: displayDate },
				{ name: 'fundsIn', label: 'Fund In', formatter: displayAmount },
				{ name: 'amountPlayed', label: 'Amount Played', formatter: displayAmount },
				{ name: 'netTerminalIncome', label: 'Net Terminal Income', formatter: displayAmount },
				{ name: 'locationRevenue', label: 'Location Revenue', formatter: displayAmount },
			]}
		/>
	)
}

export default DailyBreakdownTable
