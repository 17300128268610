import * as Headless from '@headlessui/react'
import { Label } from 'shared/catalyst-components/fieldset'
import { Switch as CatalystSwitch } from 'shared/catalyst-components/switch'

/**
 * @typedef SwitchProps
 * @property {boolean} value
 * @property {function(boolean)} onChange
 * @property {string} label
 * @property {string} [description]
 */

/**
 * @param {SwitchProps} props
 */
const Switch = ({ value, onChange, label }) => {
	return (
		<Headless.Field className="flex items-center gap-2">
			<CatalystSwitch value={value} onChange={val => onChange(val)} />
			<Label className="text-sm sm:text-base">{label}</Label>
		</Headless.Field>
	)
}

export default Switch
