import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { useId } from 'react'

const variants = cva(
	/* base style */
	'block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6 resize-none',
	{
		variants: {
			invalid: {
				true: '!text-danger-900 !ring-danger-300 placeholder:!text-danger-300 focus:!ring-danger-500'
			}
		},
		defaultVariants: {}
	}
)

/**
 * @typedef TextAreaProps
 * @property {string} [className]
 * @property {string} [name]
 * @property {string} [label]
 * @property {React.ReactNode} [helperText] Helper text to display below the label (hide when there's error message)
 * @property {string} [cornerHint] Hint text to display next to the label
 * @property {boolean} [disabled]
 * @property {boolean} [invalid]
 * @property {string} [errorMessage]
 * @property {number} [rows] Number of visible text lines
 * @property {boolean} [hideErrorMessage] Hide error message
 */

/**
 * @param {TextAreaProps & React.TextareaHTMLAttributes} props
 */
const TextArea = ({
	className,
	name,
	label,
	helperText,
	cornerHint,
	disabled,
	invalid,
	errorMessage,
	rows = 3,
	hideErrorMessage,
	...props
}) => {
	const randomId = useId()
	const descriptionId = `${name || randomId}-description`

	const hasLabel = !!label
	const hasError = invalid && errorMessage

	return (
		<div className="w-full">
			{hasLabel && (
				<div className="flex justify-between">
					<label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
						{label}
					</label>
					{cornerHint && (
						<span className="text-xs leading-6 text-gray-500" id={descriptionId}>
							{cornerHint}
						</span>
					)}
				</div>
			)}

			<div className={cx('relative rounded-md', hasLabel && 'mt-2', invalid && 'shadow-sm')}>
				<textarea
					name={name}
					id={name}
					rows={rows}
					className={variants({ className, invalid })}
					disabled={disabled}
					aria-invalid={invalid}
					aria-describedby={descriptionId}
					{...props}
				/>

				{invalid && (
					<div className="absolute right-0 flex items-start pr-3 pointer-events-none top-2">
						<ExclamationCircleIcon className="w-5 h-5 text-danger-500" aria-hidden="true" />
					</div>
				)}
			</div>

			{/* hide helper text when there's error message */}
			{helperText && !hasError && (
				<p className="mt-2 text-sm text-gray-500" id={descriptionId}>
					{helperText}
				</p>
			)}

			{hasError && !hideErrorMessage && (
				<p className="mt-2 text-sm text-danger-600" id={descriptionId}>
					{errorMessage}
				</p>
			)}
		</div>
	)
}

TextArea.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	rows: PropTypes.number,
	hideErrorMessage: PropTypes.bool
}

export default TextArea
