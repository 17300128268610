import { Field, useField } from 'formik'
import PropTypes from 'prop-types'

import TextArea from '../../Forms/TextArea'

/**
 * @param {import('../../Forms/TextArea').TextAreaProps} props
 */
const FormikTextArea = props => {
	const [field, meta, helpers] = useField(props)
	const { error, touched } = meta
	const hasError = error && touched

	return <Field as={TextArea} {...props} invalid={hasError} errorMessage={error} />
}

FormikTextArea.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	rows: PropTypes.number,
	hideErrorMessage: PropTypes.bool
}

FormikTextArea.defaultProps = {}

export default FormikTextArea
