import * as yup from 'yup'

const amountCountSchema = yup.object().shape({
	$1: yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid $1 Audit', val => {
			if (!val) return true
			return val > 0
		}),
	$5: yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid $5 Audit', val => {
			if (!val) return true
			return val > 0 && val % 5 === 0
		}),
	$10: yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid $10 Audit', val => {
			if (!val) return true
			return val > 0 && val % 10 === 0
		}),
	$20: yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid $20 Audit', val => {
			if (!val) return true
			return val > 0 && val % 20 === 0
		}),
	$50: yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid $50 Audit', val => {
			if (!val) return true
			return val > 0 && val % 50 === 0
		}),
	$100: yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid $100 Audit', val => {
			if (!val) return true
			return val > 0 && val % 100 === 0
		}),
	'1¢': yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid 1¢ Audit', val => {
			if (!val) return true
			return val > 0 && (val * 100).toFixed() % 1 === 0
		}),
	'5¢': yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid 5¢ Audit', val => {
			if (!val) return true
			return val > 0 && (val * 100).toFixed() % 5 === 0
		}),
	'10¢': yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid 10¢ Audit', val => {
			if (!val) return true
			return val > 0 && (val * 100).toFixed() % 10 === 0
		}),
	'25¢': yup
		.number()
		.nullable()
		.test('rtAudit', 'Invalid 25¢ Audit', val => {
			if (!val) return true
			return val > 0 && (val * 100).toFixed() % 25 === 0
		}),
})

export default amountCountSchema
